@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@ng-select/ng-select/themes/ant.design.theme.css";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-schedule/styles/material.css';
@import '~@ctrl/ngx-emoji-mart/picker';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.mat-input-element {
  caret-color: white !important;
}

/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: DunbarText-Regular;
    src: url(/assets/fonts/font/DunbarText-Regular.ttf);
  }

body {
    margin: 0px;
    padding: 0px;
    font-family: 'DunbarText-Regular';
    background-color:#111111 !important;
    overflow: hidden;
}



::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-track {
  box-shadow: #888 !important;
  -webkit-box-shadow: #888 !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: #888 !important;
  -webkit-box-shadow: #888 !important;
  background-color: #888 !important;

}


mat-dialog-container {
    background-color: #111111 !important;
    border-radius: 20px !important;
    font-family: Dunbar Text;
  }

  .modal-open {
    overflow: hidden;
    position:fixed;
    width: 100%;
    height: 100%;
}

.for-slid .swiper-button-next:after, .for-slid .swiper-rtl .swiper-button-prev:after {
  content: 'next';
  background: #16161670;
  padding: 7px 10px;
  border-radius: 100%;
}
.for-slid .swiper-button-prev:after, .for-slid .swiper-rtl .swiper-button-next:after {
  content: 'prev';
  background: #16161670;
  padding: 7px 10px;
  border-radius: 100%;
}
.for-slid .swiper-button-prev:after, .for-slid .swiper-button-next:after {
  margin-top: -31px !important;
  font-size: 15px !important;
}

.joinleft .card{
    background-color: #111111;
}
.joinright{
    margin-left: 0px!important;
}
.ghbt .mat-chip-list-wrapper{
  margin: auto!important;
}
.ghbt .mat-chip-list-wrapper{
  color: #fff;
  font-size: 12px;
}
:focus {
  outline: none;
}
.hgni .mat-select{
  padding-top: 8px;
}
.ghtw, .kdjnb{
  margin-top: 74px;
}

.wi-4{
  // width: 410px;
  width: 557px;
}


.popover-body {
  padding: 0 !important;
  margin-top: -145px !important;
  margin-left: 10px;
}

.card2{
  width: 489px;
  height: 658px;
  border-radius: 10px;
  margin: 0 auto;
}
.hgns{
  position: relative;
}
// slider css start

.carousel.carousel-fade .carousel-item {
    display: block;
    opacity: 0;
    transition: opacity ease-out .7s;
  }

  .carousel.carousel-fade .carousel-item.active {
    opacity: 1 !important;
  }
  .embed-responsive {
    position: relative;
    display: block;
    width: 400px;
    padding: 0;
    height: 500px;
    overflow: hidden;
  }
  .sl-de .img-fluid {
    /* max-width: 100%; */
    /* height: auto; */
    width: 70%;
    margin-top: 35px;
    height: 465px;
    border-radius: 10px;
  }
  // slider css end



// genral edit css

.edu .mat-select-value-text{
  color: #fff !important;
}
.edu .mat-select-arrow{
  border-left: 5px solid #ffffff00;
  border-right: 5px solid #ffffff00;
  border-top: 5px solid white;
}
.edu .mat-form-field-underline, .loca .mat-form-field-underline, .batski .mat-form-field-underline {
  display: none!important;
}

.edu .mat-form-field-wrapper, .loca .mat-form-field-wrapper {
  padding-bottom: 0px;
}
.edu .mat-form-field-infix, .loca .mat-form-field-infix {
  padding: .5em 0;
  border-top: 0;
}
.mat-autocomplete-panel-above .mat-autocomplete-panel {
  background: #111111;
}
.edu .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: 10px;
  padding-top: 0;
}
.edu .mat-pseudo-checkbox, .loca .mat-pseudo-checkbox{
  color: #f6f6f6!important;
}
.edu .mat-select-panel, .loca .mat-select-panel{
  background: #111111!important;
}
.edu .mat-primary .mat-option.mat-selected:not(.mat-option-disabled), .loca .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #dfdfdf!important;
}
.edu .mat-select-panel .mat-option, .loca .mat-select-panel .mat-option {
  color: white!important;
}
.edu .mat-form-field-appearance-fill .mat-select-arrow-wrapper, .loca .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  padding-top: 10px!important;
}
.loca .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: 0px 10px;
}
.loca .mat-standard-chip.mat-chip-with-trailing-icon {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 8px;
  padding-left: 12px;
  background: #111111;
  border: 1px solid #E0E7EC;
  box-sizing: border-box;
  border-radius: 5px;
  color: #fff;
}
.ch-pi .mat-chip.mat-standard-chip .mat-chip-remove {
  opacity: 1.4;
  background: #111111;
  color: #fff;
  border: 0;
  margin-left: 10px;
  margin-right: 0px;
  height: 14px;
  width: 0px
}
.ch-pi .mat-standard-chip {
  min-height: 28px!important;
}
.ch-pi .mat-standard-chip .mat-chip-remove, .ch-pi .mat-standard-chip .mat-chip-trailing-icon {
  margin-left: 0px;
  margin-right: 4px;
}
#style-2::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar
{
    width: 8px;
    background-color: linear-gradient(95.55deg, #EE2737 -18%, #F93822 18.89%, rgba(255, 103, 32, 0.92) 57.66%, #A20067 110.66%);
}

#style-2::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #D62929;
}
//  .mat-standard-chip .mat-chip-remove, .mat-standard-chip .mat-chip-trailing-icon {
//   width: 0px;
//   height: 15px;
//   cursor: pointer;
// }
.ch-pi .mat-icon {
  display: inline-block;
  fill: currentColor;
  height: 18px;
  width: auto;
}
.material-icons{
  font-size: 17px !important;
}


// new css 14th feb
.yr-ob .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #fff!important;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: rgba(255, 255, 255, 0)!important;
}
.ghbt .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #fff!important;
}
.ghbt .material-icons {
  font-size: 11px !important;
  margin-right: 0 !important;
  border: 1px solid;
  border-radius: 100%;
  width: auto !important;
  height: auto !important;
}
.batski .mat-form-field-infix {
  border-top: 0px!important;
}
.batski .mat-form-field-appearance-fill .mat-form-field-flex{
  padding: 0px;
}
.batski .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #fff!important;
}
.batski .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #fff !important;
  opacity: 0.9 !important;
  background: none;
  border: 0;
  margin: 0;
  font-size: 13px !important;
  width: auto !important;
  height: auto !important;
}

.rgtaa .mat-chip.mat-standard-chip {
  background-color: #e0e0e000;
  color: rgb(255 255 255 / 87%);
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 0px 4px;
}
.rgtaa .mat-standard-chip .mat-chip-remove.mat-icon {
  width: auto;
  height: auto;
}
.rgtaa .mat-form-field-appearance-outline .mat-form-field-infix, .axcr .mat-form-field-appearance-outline .mat-form-field-infix {
  // padding: 27px 0px!important;
  padding: 9px 0px !important;
}
.rgtaa .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgb(255 255 255 / 87%);
  opacity: 1;
  border: 1px solid #fff;
  border-radius: 100%;
}

// 16th feb
.ithy.gfbh-back td{
  width: 4%;
}


  @media (min-width: 767px) and (max-width: 1024px){
    .joinfoot {
        position: relative!important;
        bottom: 0;
        width: 100%;
    }
  }

  @media only screen and (max-width: 767px){
    .embed-responsive {

        width: 100%;

      }
      .joinb {
        width: 100%;
    }
    .ujhgy{
        text-align: center;
      }
      .ujhgy h5{
        margin-top: 20px;
    }
    .joinfoot {
        position: relative;
        bottom: 0;
        width: 100%;
    }
  }
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";


.swiper-button-prev, .swiper-button-next {
  color: white !important;

}

.swiper-button-prev:after, .swiper-button-next:after {
  margin-top: -31px !important;
  font-size: 29px !important;

}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  display: none !important;
}
.mb-5.fercc{
  max-height: 400px;
    // overflow-x: hidden;
    // overflow-y: auto;
}
// .hftuu{

// }
::ng-deep .hftuu .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.055) !important;
  opacity: 0!important;
}
// .hftuu .mat-chip.mat-standard-chip {
//   background-color: #e0e0e000;
//   color: rgb(255 255 255 / 87%);
//   border: 1px solid #fff;
//   border-radius: 5px;
//   padding: 0px 4px;
// }
.hftuu .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #fff !important;
}
.hftuu .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.375rem 0.75rem !important;
  height: 50px;
  overflow-y: scroll;
}
// .hftuu .mat-form-field-appearance-outline .mat-form-field-infix {
//   padding: 0.375rem 0.75rem !important;
//   height: 50px;
//   overflow-y: scroll;
// }
.hftuu.dee .mat-form-field-appearance-outline .mat-form-field-infix{
  padding-top: 16px !important;
}
.hftuu .mat-form-field-appearance-outline .mat-form-field-infix::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

ng-select .ng-select-container {
  color: white !important;
  background-color: black !important;
  border-radius: 47px !important;

}


ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #3f51b5;
  border-radius: 2px;
  color: black !important;
  padding: 0px 5px !important;
}

ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  color: black;
}

ng-select.ng-select-opened>.ng-select-container {
 border-color: none;
}
.mat-select-placeholder {
  color: white !important;
}

ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
  color: #FFE !important;
}




.ng-dropdown-panel.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #121111 !important;
  padding: 5px 12px;
  color: white !important;
  transition: background .3s ease;
}


.ng-dropdown-panel {
  border: 0px solid #ccc;
}


// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
//   display: none !important;
// }

ng-select.ng-select-opened>.ng-select-container {
   border-color: white !important;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
   border-color: white !important;
  box-shadow: none !important;
}


.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

::ng-deep .yr-ob .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.055) !important;
  opacity: 0!important;
}


.ng-select.disable-arrow .ng-arrow-wrapper .ng-arrow {
  display: none;
}

.ng-select.disable-clear-all .ng-clear-wrapper {
  display: none;
}


.bs-chevron {
  color: white !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  color: white !important;
}


.bs-timepicker-field {
  background-color: black !important;
  color: white !important;
}


// .pac-container, .pac-item {
//   background-color: #000000b5 !important;
//   color: white !important;
//   text-align: left !important;
//  }

.pac-container {

  margin-top: 20px;

}

.pac-container, .pac-item {
  background-color:#111111 !important;
  cursor: default;
  z-index: 99999;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 0px solid #e6e6e6;
  border-bottom: 0px solid #e6e6e6;
  font-size: 11px;
  color: white;
}

.pac-item-query{
  color: white !important;
}


input:focus, input.form-control:focus {
 outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

textarea:focus, textarea.form-control:focus {
  outline:none !important;
   outline-width: 0 !important;
   box-shadow: none;
   -moz-box-shadow: none;
   -webkit-box-shadow: none;
 }

 .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
   display: none !important;
  }

.ng2-pdf-viewer-container{
    height: 50% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
// Radio button css
.radio {
  &-input {
    visibility: hidden;
  }

  &-label {
    position: relative;
    padding-left: 35px;
    &:after {
      content: ""; display: block; width: 12px; height: 12px; position: absolute; left: 4px; top: 4px; border-radius: 50%;
    }
  }

  &-border {
    width: 20px; height: 20px; display: inline-block; outline: solid 3px #808080a8; border-radius: 50%; position: absolute; left: 0px; top: 0px;
  }
  &-input:checked + &-label:after {
    transition: all 0.5s; background-color: red;
  }

  .radio-border {
    width: 20px !important;
    height: 20px !important;
    display: inline-block !important;
    box-shadow: 0 0 0 2px grey !important;
    border-radius: 50% !important;
    position: absolute !important;
    left: 0px !important;
    top: 0px !important;
  }
}
.pointer {
  cursor: pointer;
}

.mat-expansion-indicator::after {
  color: white !important;
}

.mat-list-base .mat-list-item {
  font-size: 14px;
}
.white{
  color: white;
}
.breakLine {
  word-break: break-all;
}

